<template>
  <div>
    <!-- Alert: No item found -->
    <b-alert variant="danger" :show="userKarmaCatalogData === undefined">
      <h4 class="alert-heading">Error fetching user data</h4>
      <div class="alert-body">
        No user found with this id. Check
        <b-link class="alert-link" :to="{ name: 'list-karma-catalogs' }">
          catalog List
        </b-link>
      </div>
    </b-alert>

    <template v-if="userKarmaCatalogData">
      <!-- First Row -->
      <b-row>
        <b-col cols="12" xl="12" lg="12" md="12">
          <KarmaCatalogueViewInfo
            :user-data="userKarmaCatalogData"
            :custom-fields="customFields"
          />
        </b-col>
      </b-row>
    </template>

    <template v-if="userKarmaCatalogData">
      <!-- First Row -->
      <b-row>
        <b-col cols="12" xl="12" lg="12" md="12">
          <b-card title="Activities">
            <app-timeline>
              <app-timeline-item-orignal
                v-for="(item, index) in activities"
                v-bind:key="index"
              >
                <div
                  class="
                    d-flex
                    flex-sm-row flex-column flex-wrap
                    justify-content-between
                    mb-1 mb-sm-0
                  "
                >
                  <h6 class="text-capitalize">{{ item.event }}</h6>
                  <small class="text-muted">{{ item.created_at }}</small>
                </div>
                <!-- <p>{{item.description}}</p> -->
                <b-media v-if="item.user">
                  <template #aside>
                    <b-avatar :src="item.user.avatar" />
                  </template>
                  <h6>{{ item.user.name }}</h6>
                  <p class="mb-0">{{ item.user.designation }}</p>
                </b-media>
                <app-collapse>
                  <app-collapse-item title="Attributes">
                    <div v-for="(item, key) in item.properties.attributes">
                      <div class="d-flex text-capitalize">
                        <div class="text-capitalize mr-1">
                          {{ key.replace(/_/g, " ") }}
                        </div>
                        <div>{{ item }}</div>
                      </div>
                    </div>
                  </app-collapse-item>
                </app-collapse>
              </app-timeline-item-orignal>
            </app-timeline>
          </b-card>
        </b-col>
      </b-row>
    </template>
  </div>
</template>

<script>
import store from "@/store";
import router from "@/router";
import { ref, onUnmounted } from "@vue/composition-api";
import {
  BRow,
  BCol,
  BAlert,
  BLink,
  BTabs,
  BTab,
  BCard,
  BCardText,
  BDropdown,
  BDropdownItem,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  VBTooltip,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { VueGoodTable } from "vue-good-table";
import karmaCatalogStoreModule from "../karmaCatalogueStoreModule";
import KarmaCatalogueViewInfo from "./KarmaCatalogueViewInfo.vue";
import AppTimeline from "@core/components/app-timeline/AppTimeline.vue";
import AppTimelineItemOrignal from "@core/components/app-timeline/AppTimelineItemOrignal.vue";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";

export default {
  components: {
    BRow,
    BCol,
    BAlert,
    BLink,
    BTabs,
    BTab,
    BCard,
    BCardText,
    BDropdown,
    BDropdownItem,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    KarmaCatalogueViewInfo,
    VueGoodTable,
    AppTimeline,
    AppTimelineItemOrignal,
    AppCollapse,
    AppCollapseItem,
  },
  directives: {
    Ripple,
    "b-tooltip": VBTooltip,
  },
  setup() {
    const userKarmaCatalogData = ref(null);
    const karma_catalog = ref({
      name: "",
      id: "",
    });
    const USER_APP_STORE_MODULE_NAME = "app-karma-catalogue";

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME))
      store.registerModule(USER_APP_STORE_MODULE_NAME, karmaCatalogStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME))
        store.unregisterModule(USER_APP_STORE_MODULE_NAME);
    });

    store
      .dispatch(
        "app-karma-catalogue/fetchKarmaCatalog",
        router.currentRoute.params.id
      )
      .then((response) => {
        userKarmaCatalogData.value = response.data.data;
        karma_catalog.value.name = userKarmaCatalogData.value.name;
        karma_catalog.value.id = userKarmaCatalogData.value.hashid;
      })
      .catch((error) => {
        if (error.response.status === 404) {
          userKarmaCatalogData.value = undefined;
        }
      });
    return {
      userKarmaCatalogData,
      karma_catalog,
    };
  },
  data() {
    return {
      activities: [],
      pageLength: 3,
      karmaCatalogChangeFlag: true,
      pageLengthEmployee: 3,
      dir: false,
      isAddNewInterviewSidebarActive: false,
      columns: [
        {
          label: "Interview Title",
          field: "title",
        },
        {
          label: "Start At",
          field: "start_at",
        },
        {
          label: "End At",
          field: "end_at",
        },
        {
          label: "Action",
          field: "action",
        },
      ],
      searchTerm: "",
      customFields: [],
    };
  },
  created() {
    this.getCustomFields();
    this.getActivity();
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true;
        return this.dir;
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false;
      return this.dir;
    },
  },
  methods: {
    viewInterview(id) {
      this.$router.push(`/view/interview/${id}`).catch(() => {});
    },
    assignInterview() {
      console.log("assigning interview...");
      this.isAddNewInterviewSidebarActive = true;
    },
    refetchKarmaCatalogueData() {
      console.log("refetching started..");
    },
    getCustomFields() {
      const self = this;
      self.$store
        .dispatch("app-karma-catalogue/getCustomFields")
        .then((res) => {
          self.customFields = res.data.data;
        })
        .catch((error) => {
          console.log(error);
          self.$toast({
            component: ToastificationContent,
            props: {
              title: "Oops! Custom Fields Getting Failed",
              icon: "BellIcon",
              variant: "danger",
            },
          });
        });
    },
    getActivity() {
      const self = this;

      let data = {};
      data.url = "karma-catalogue/activity/" + router.currentRoute.params.id;
      this.isBusy = true;
      this.$store
        .dispatch("app/get", data)
        .then((res) => {
          this.isBusy = false;
          self.activities = res.data.data.activities;
        })
        .catch((error) => {
          this.isBusy = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Oops! Getting Karma Catalog Activity Failed",
              icon: "BellIcon",
              variant: "danger",
              text: error,
            },
          });
        });
    },
    containsKey(obj, key) {
      return Object.keys(obj).includes(key);
    },
  },
};
</script>

<style lang="scss" >
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>

<style lang="scss" scoped>
.add-btn {
  border: solid 1px;
  width: 70%;
}
.interview-pass {
  color: green;
}
.interview-fail {
  color: indianred;
}
</style>